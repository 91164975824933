<template>
  <div>
    <b-row>
      <b-col cols="12" md="6">
        <div
          class="d-flex align-items-center mb-2"
          :style="isDragging ? 'pointer-events: none' : ''"
        >
          <b-form-file
            ref="refInputGlobalEl"
            accept=".xlsx, .xls, .csv"
            placeholder="Arraste y suelte o haga clic para subir los participantes"
            multiple
            :disabled="loading"
            @change="changeInputFile"
          />
        </div>
      </b-col>
      <b-col cols="12" md="4" offset-md="2">
        <b-form-input
          id="search"
          v-model="search"
          :disabled="loading"
          placeholder="Buscar participante"
          trim
        />
      </b-col>
    </b-row>

    <b-table
      :items="participantsFilter"
      :fields="tableColumns"
      class="position-relative"
      primary-key="id"
      empty-text="No se encontraron participantes"
      show-empty
      striped
      responsive
    >
      <template #cell(only_participations)="data">
        <div style="width: 200px;">
          <v-select
            v-model="data.item.only_participations"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="awardsFormatter"
            :clearable="false"
            :disabled="loading"
            multiple
          />
        </div>
      </template>

      <template #cell(weight_up)="data">
        <div style="width: 60px;">
          <b-form-input
            v-model="data.item.weight_up"
            :disabled="loading"
            type="number"
            trim
          />
        </div>
      </template>
    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="currentPageSize"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>

    <backdrop-files
      v-if="isDragging"
      text="Suelta el excel aquí para importar los participantes"
    />
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BFormFile,
  BFormInput,
  BTable,
  BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { read, utils } from 'xlsx'
import BackdropFiles from '@/views/banner/BackdropFiles.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormFile,
    BFormInput,
    BTable,
    BPagination,
    vSelect,

    BackdropFiles,
  },
  directives: {
    Ripple,
  },
  props: {
    participants: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    awards: {
      type: Array,
      required: true,
    },
    loading: Boolean,
  },
  emits: ['update:participants', 'import'],
  created() {
    window.addEventListener('dragover', this.dragover)
    window.addEventListener('dragleave', this.dragleave)
    window.addEventListener('drop', this.drop)
  },
  destroyed() {
    window.removeEventListener('dragover', this.dragover)
    window.removeEventListener('dragleave', this.dragleave)
    window.removeEventListener('drop', this.drop)
  },
  setup(props, { emit }) {
    const tableColumns = [
      { label: 'Client ID', key: 'external_id' },
      { label: 'Nombre', key: 'name' },
      { label: 'Correo', key: 'email' },
      { label: 'Teléfono', key: 'phone' },
      { label: 'Solo participa para?', key: 'only_participations' },
      { label: 'Peso', key: 'weight_up' },
    ]

    const refInputGlobalEl = ref(null)

    const isDragging = ref(false)

    const search = ref('')
    const currentPage = ref(1)
    const currentPageSize = ref(10)
    const totalRows = ref(0)

    const participantsFilter = computed(() => {
      let list = props.participants

      if (search.value) {
        const query = search.value.toLowerCase()

        list = props.participants.filter(
          participant => String(participant.external_id).toLowerCase().includes(query)
            || String(participant.name).toLowerCase().includes(query)
            || String(participant.email).toLowerCase().includes(query)
            || String(participant.phone).toLowerCase().includes(query),
        )
      }

      const start = (currentPage.value - 1) * currentPageSize.value
      const end = start + currentPageSize.value

      totalRows.value = list.length
      return list.slice(start, end)
    })

    // eslint-disable-next-line arrow-body-style
    const awardsFormatter = computed(() => {
      return props.awards
        .filter(award => award.name)
        .map((award, index) => ({
          label: award.name,
          value: award.key || award.id,
          index,
        }))
    })

    const changeInputFile = async e => {
      let inputFiles = []

      if (e instanceof Event) {
        inputFiles = e.target.files
      } else if (e instanceof FileList) {
        inputFiles = e
      } else if (Array.isArray(e)) {
        inputFiles = e
      } else {
        inputFiles = refInputGlobalEl.value?.files ?? []
      }

      const file = inputFiles[0]

      const reader = new FileReader()

      reader.onload = event => {
        const data = event.target.result

        const workbook = read(data, { type: 'binary' })
        const worksheet = workbook.Sheets[workbook.SheetNames[0]]
        const json = utils.sheet_to_json(worksheet, { defval: null, raw: false })

        const findByKey = (key, entrie) => {
          const val = entrie.find(([k]) => k.toLowerCase().includes(key.toLowerCase()))
          return val ? val[1] : null
        }

        const formatJson = json.map(i => {
          const entrie = Object.entries(i)

          return {
            external_id: findByKey('Client', entrie),
            name: findByKey('Nombre', entrie),
            email: findByKey('Correo', entrie),
            phone: findByKey('Celular', entrie),
            only_participations: [],
            weight_up: 1,
          }
        })

        emit('update:participants', formatJson)

        refInputGlobalEl.value.reset()
      }
      reader.readAsBinaryString(file)
    }

    // Methods Drag and drop file
    const dragover = e => {
      e.preventDefault()
      isDragging.value = true
    }

    const dragleave = () => {
      isDragging.value = false
    }

    const drop = e => {
      e.preventDefault()
      changeInputFile(e.dataTransfer.files)
      isDragging.value = false
    }

    return {
      refInputGlobalEl,
      isDragging,
      dragover,
      dragleave,
      drop,
      changeInputFile,
      search,

      awardsFormatter,
      tableColumns,
      currentPage,
      currentPageSize,
      participantsFilter,
      totalRows,
    }
  },
}
</script>
