import LotteryRequest from '@/@api/lottery'
import { ref, computed } from '@vue/composition-api'
import { downloadExcel } from '@/@core/utils/excel'
// import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { debouncedWatch } from '@vueuse/core'
import { dateFormatter } from '@/@core/utils/date'

const lotteryList = ref([])

export default function useLottery() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // const lotteryList = ref([])

  // Table Handlers
  const tableColumns = [
    {
      key: 'id',
      label: '#',
      sortable: true,
      thStyle: { width: '100px' },
    },
    {
      label: 'Nombre',
      key: 'title',
      sortable: true,
      // thStyle: { width: '100%' },
    },
    { label: 'Nro de premios', key: 'countAwards', sortable: true },
    { label: 'Fecha programada', key: 'schedule_end', sortable: true },
    // { label: 'Estado', key: 'state', sortable: true },
    { label: 'Activo', key: 'status', sortable: true },
    { label: 'Acciones', key: 'actions' },
  ]
  const perPage = ref(10)
  const totalList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  debouncedWatch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  }, { debounce: 400 })

  const fetchList = (ctx, callback) => {
    LotteryRequest.list({
      params: {
        q: searchQuery.value,
        paginate: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      },
    })
      .then(response => {
        const { data, total } = response.data.data

        callback(data.map(item => {
          const scheduleEnd = dateFormatter(item.schedule_end)

          return {
            ...item,
            schedule_end: scheduleEnd,
            countAwards: item.extras ? JSON.parse(item.extras)?.awards?.length ?? 0 : 0,
            loading: false,
          }
        }))
        totalList.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching lotteryList list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  async function fetchSelector(isMemo = false) {
    if (isMemo && lotteryList.value.length) {
      return Promise.resolve(lotteryList.value)
    }

    try {
      const { data } = await LotteryRequest.select()

      const list = data.data.map(item => ({
        id: item.id,
        value: item.id,
        label: item.name,
      }))

      lotteryList.value = list

      return list
    } catch (error) {
      throw new Error(error)
    }
  }

  async function store(body) {
    try {
      const { data } = await LotteryRequest.store(body)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function update(id, body) {
    try {
      const { data } = await LotteryRequest.update(id, body)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function destroy(id) {
    try {
      const { data } = await LotteryRequest.destroy(id)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function exportExcel(params = {}) {
    try {
      const response = await LotteryRequest.list({ params })
      const { data } = response.data.data

      downloadExcel(data, 'Sorteos.xlsx')
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Ups! Ocurrio un error al exportar',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // fetchSelector(true).then(list => {
  //   lotteryList.value = list
  // })

  return {
    lotteryList,
    fetchList,
    fetchSelector,
    tableColumns,
    perPage,
    currentPage,
    totalList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,

    refetchData,
    store,
    update,
    destroy,
    exportExcel,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
