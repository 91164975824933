<template>
  <div>
    <div class="d-flex align-items-baseline justify-content-between mb-1 cursor-move">
      <div class="d-flex align-items-baseline">
        <h5>Posición: {{ positionFromText }}</h5>

        <small
          class="ml-1 cursor-pointer text-underline"
          @click="focusTo"
        >Hasta</small>

        <b-form-input
          ref="refInputTo"
          v-show="isFocusTo"
          v-model="data.to"
          :disabled="loading"
          placeholder="Rango hasta"
          class="ml-1"
          style="width: 110px"
          size="sm"
          type="number"
          number
          trim
          @blur="onBlur"
        />
        <h5 v-show="!isFocusTo" class="ml-1 cursor-pointer text-underline" @click="focusTo">{{ positionToText }}</h5>
      </div>
      <div>
        <b-button
          :id="`${position._uid}-row-delete-icon`"
          size="sm"
          type="icon"
          variant="outline-danger"
          @click="$emit('destroy')"
        >
          <feather-icon icon="XIcon" size="14" />
        </b-button>
        <b-tooltip
          title="Eliminar premio"
          :target="`${position._uid}-row-delete-icon`"
          placement="left"
        />
      </div>
    </div>
    <b-card class="position-border" no-body>
      <b-card-body class="pb-1">
        <b-row>
          <b-col
            v-for="(award, key) in data.awards"
            :key="award._uid + '-' + key"
            cols="12"
            md="3"
          >
            <div
              class="d-flex justify-content-center position-relative cursor-pointer position-image-container"
            >
              <b-button
                size="sm"
                type="icon"
                variant="outline-danger"
                class="btn-remove"
                @click="awardRemove(key)"
              >
                <feather-icon icon="XIcon" size="14" />
              </b-button>
              <div
                :style="`background-image: url(${awards[award._uid] ? awards[award._uid].image : ''}); background-repeat: no-repeat; background-position: center; background-size: contain;`"
                class="rounded mb-1 mb-md-0 position-image"
              >
                <div class="position-award-text">
                  {{ awards[award._uid] ? awards[award._uid].name : '' }}
                </div>
              </div>
            </div>
            <b-form-group class="mt-1 mb-25">
              <b-form-input
                v-model="award.quantity"
                :disabled="loading"
                placeholder="Cantidad"
                type="number"
                number
                trim
              />
              <small
                v-show="awardQuantityUse[award._uid] > awards[award._uid].quantity"
                class="form-text text-danger"
              >
                La cantidad total debe ser menor o igual a {{ awards[award._uid].quantity }}
              </small>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3">
            <div
              class="position-relative cursor-pointer position-image-container d-flex flex-column mb-1 mt-25"
              @click="onClickDropdown"
            >
              <b-dropdown
                ref="refDropdown"
                size="lg"
                variant="link"
                toggle-class="p-0"
                no-caret
              >
                <template #button-content />
                <b-dropdown-item
                  v-for="award in awards"
                  :key="award._uid"
                  :disabled="awardQuantityUse[award._uid] >= award.quantity"
                  href="#"
                  @click="addAward(award)"
                >
                  {{ award.name }} ({{ awardQuantityUse[award._uid] ? awardQuantityUse[award._uid] : 0 }}/{{ award.quantity }})
                </b-dropdown-item>
              </b-dropdown>
              <div
                class="image_placeholder position-image"
              />
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BFormGroup,
  BFormInput,
  BTooltip,
  BButton,
  // BImg,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { computed, ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
// import vSelect from 'vue-select'
import { v4 as uuidv4 } from 'uuid'
import { dicPositions } from '@/@core/constants'
import useCategories from '@/views/categories-award/useCategories'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormGroup,
    BFormInput,
    // BFormTextarea,
    BTooltip,
    BButton,
    // BImg,
    BDropdown,
    BDropdownItem,
    // vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    position: {
      type: Object,
      required: true,
    },
    awards: {
      type: [Array, Object],
      required: true,
    },
    awardQuantityUse: {
      type: [Array, Object],
      required: true,
    },
    loading: {
      type: Boolean,
    },
  },
  emits: ['update:position', 'changePositions'],
  computed: {
    positionFromText() {
      return dicPositions[this.position.from]
        ? `${dicPositions[this.position.from]} lugar`
        : this.position.from
    },
    positionToText() {
      return dicPositions[this.position.to]
        ? `${dicPositions[this.position.to]} lugar`
        : this.position.to
    },
  },
  setup({ position }, { emit }) {
    const uid = uuidv4()
    const { categories } = useCategories()

    const refDropdown = ref(null)
    const refInputTo = ref(null)

    const isFocusTo = ref(false)

    const data = computed({
      get: () => position,
      set: val => {
        emit('update:position', val)
      },
    })

    function onClickDropdown() {
      refDropdown.value.$refs.toggle.click()
    }

    function addAward(award) {
      data.value.awards = [
        ...data.value.awards,
        {
          _uid: award._uid,
          id: award.id,
          quantity: 1,
        },
      ]
    }

    function awardRemove(i) {
      data.value.awards = data.value.awards.filter((_, j) => j !== i)
    }

    function focusTo() {
      if (refInputTo.value) {
        isFocusTo.value = true

        setTimeout(() => {
          refInputTo.value.focus()
        }, 50)
      }
    }

    function onBlur() {
      isFocusTo.value = false
      const { from, to } = data.value

      if (from >= to) {
        data.value.to = null
      }

      emit('changePositions')
    }

    return {
      uid,
      data,
      categories,
      refDropdown,
      refInputTo,
      isFocusTo,
      focusTo,
      onBlur,
      onClickDropdown,
      addAward,
      awardRemove,
    }
  },
}
</script>

<style lang="scss">
.position-border {
  --border-color: #ebe9f1;

  border: 1px solid var(--border-color);
}

.position-image {
  width: 100%;
  height: 140px;
}

.position-image-container {
  .position-image {
    width: 100%;
    padding-bottom: 64%;
  }

  .btn-remove {
    position: absolute;
    top: 6px;
    right: 6px;
    border: 0 !important;
  }
}

.position-award-text {
  --bg-color: rgba(255, 255, 255, 0.95);

  background: linear-gradient(0, var(--bg-color) 65%, transparent);
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0.75rem 0.5rem 0.25rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.dark-layout {
  .position-border {
    --border-color: #6c7282;
  }

  .image_placeholder {
    --color: #6c7282;
  }

  .position-award-text {
    --bg-color: rgba(40, 48, 70, 0.95);
  }
}
</style>
