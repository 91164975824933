import http from './http'
import Resource from './resource'

const path = 'admin/tombola/companies'

class Commerce extends Resource {
  constructor(_http) {
    super(path, _http)
  }

  select(opt = {}) {
    return this.http.get(`api/${path}/select/list`, opt)
  }
}

const CommerceRequest = new Commerce(http)

export default CommerceRequest
