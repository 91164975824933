import http from './http'
import Resource from './resource'

const path = 'admin/tombola/award-categories'

class CategoriesAward extends Resource {
  constructor(_http) {
    super(path, _http)
  }

  select(opt = {}) {
    return this.http.get(`api/${path}/select/list`, opt)
  }
}

const CategoriesAwardRequest = new CategoriesAward(http)

export default CategoriesAwardRequest
