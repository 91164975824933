<template>
  <section class="invoice-add-wrapper position-relative">
    <div v-show="loadingEdit" class="spinner-container position-absolute zindex-4 w-100 h-100 rounded-lg text-center p-5">
      <b-spinner />
    </div>

    <b-row class="invoice-add">

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-form @submit.prevent>
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing my-0">

                <!-- Header: Left Content -->
                <div class="flex-grow-1 pr-5">
                  <b-form-group
                    label="Título"
                    label-for="title"
                  >
                    <b-form-input
                      id="title"
                      v-model="data.title"
                      :disabled="loading"
                      trim
                    />
                  </b-form-group>
                </div>

                <!-- Header: Right Content -->
                <div class="invoice-number-date mt-md-0 mt-2">
                  <h6 class="mb-2">
                    Programar fecha:
                  </h6>
                  <div class="d-flex align-items-center mb-1">
                    <span class="title">
                      Desde:
                    </span>
                    <flat-pickr
                      v-model="data.schedule_begin"
                      class="form-control invoice-edit-input"
                      :config="{ enableTime: true, dateFormat: 'Y-m-d H:i'}"
                      :disabled="loading"
                    />
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="title">
                      Hasta:
                    </span>
                    <flat-pickr
                      v-model="data.schedule_end"
                      class="form-control invoice-edit-input"
                      :config="{ enableTime: true, dateFormat: 'Y-m-d H:i'}"
                      :disabled="loading"
                    />
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Description -->
            <b-card-body class="invoice-padding py-25">
              <b-form-group
                label="Descripción:"
                label-for="description"
              >
                <b-form-textarea
                  id="description"
                  v-model="data.description"
                  :disabled="loading"
                  rows="3"
                />
              </b-form-group>
            </b-card-body>

            <hr class="invoice-spacing">

            <b-card-body>
              <b-tabs fill>
                <!-- Tab Participantes -->
                <b-tab title="Participantes" active>
                  <hr class="mt-n1 mb-2">

                  <lottery-participants-table
                    :participants="participants"
                    :awards="awards"
                    @update:participants="val => (participants = val)"
                  />
                </b-tab>

                <!-- Tab Prémios -->
                <b-tab title="Prémios">
                  <hr class="mt-n1 mb-2">

                  <lottery-award-form
                    v-for="(award, key) in awards"
                    :key="award.id || award._uid"
                    :award="award"
                    :loading="loading"
                    @update:award="val => (award = val)"
                    @destroy="destroyAward(key)"
                  />

                  <div class="d-flex justify-content-end">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="primary"
                      :disabled="loading"
                      @click="addAward"
                    >
                      Agregar premio
                    </b-button>
                  </div>
                </b-tab>

                <!-- Tab Posiciones -->
                <b-tab title="Posiciones">
                  <hr class="mt-n1 mb-2">

                  <draggable
                    :list="positions"
                    @change="onChangePositions"
                  >
                    <lottery-position-form
                      v-for="(position, key) in positions"
                      :key="position.id || position._uid"
                      :index="key"
                      :position="position"
                      :awards="awardComputed"
                      :award-quantity-use="awardQuantityUse"
                      :loading="loading"
                      @update:position="val => (position = val)"
                      @destroy="destroyPosition(key)"
                      @changePositions="onChangePositions"
                    />
                  </draggable>

                  <div class="d-flex justify-content-end">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="primary"
                      :disabled="loading"
                      @click="addPosition"
                    >
                      Agregar posición
                    </b-button>
                  </div>

                </b-tab>
              </b-tabs>
            </b-card-body>

          </b-card>
        </b-form>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >
        <!-- Action Buttons -->
        <b-card>
          <!-- Payment Method -->
          <div class="mb-2">
            <!-- Select Commerce -->
            <b-form-group
              label="Solo visible para"
              label-for="commerce"
            >
              <v-select
                v-model="data.commerces"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="commerces"
                input-id="commerce"
                :clearable="false"
                :disabled="loading"
                multiple
              />
            </b-form-group>

            <b-form-group
              label="Excluir"
              label-for="input-blacklist"
            >
              <v-select
                v-model="data.blacklist"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="countries"
                :disabled="loading"
                input-id="input-blacklist"
                multiple
                :close-on-select="false"
              />
            </b-form-group>

            <hr class="invoice-spacing">

            <b-form-group
              label="Estado"
              label-for="input-status"
            >
              <v-select
                v-model="data.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statuses"
                input-id="input-status"
                class="payment-selector"
                :clearable="false"
                :disabled="loading"
                label="text"
              />
            </b-form-group>
          </div>

          <hr class="invoice-spacing">

          <div
            class="d-flex justify-content-between align-items-center my-2"
          >
            <label
              for="required_substitutes"
              class="w-100 d-flex justify-content-between mr-1 font-small-3"
            >Requiere suplente <span>{{ data.required_substitutes ? 'SI' : 'NO' }}</span></label>
            <b-form-checkbox
              id="required_substitutes"
              v-model="data.required_substitutes"
              :disabled="loading"
              switch
            />
          </div>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            :disabled="loading"
            block
            @click="onSubmit"
          >
            <template v-if="loading">
              <b-spinner
                class="mr-1"
                label="Small Spinning"
                small
              />
              Guardando...
            </template>
            <template v-else>
              Guardar
            </template>
          </b-button>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            :disabled="loading"
            block
            type="button"
            @click="saveAndNew"
          >
            <template v-if="loading">
              <b-spinner
                class="mr-1"
                label="Small Spinning"
                small
              />
              Guardando...
            </template>
            <template v-else>
              Guardar y crear otro
            </template>
          </b-button>
        </b-card>
      </b-col>
    </b-row>

    <!-- <backdrop-files
      v-if="isDragging"
      text="Suelta el excel aquí para importar los participantes"
    /> -->
  </section>
</template>

<script>
import {
  ref,
  onMounted,
  computed,
} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormCheckbox,
  BFormInput,
  BSpinner,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment-timezone'
import { v4 as uuidv4 } from 'uuid'
import draggable from 'vuedraggable'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import LotteryRequest from '@/@api/lottery'
import { useRouter } from '@/@core/utils/utils'
// import BackdropFiles from '@/views/banner/BackdropFiles.vue'

import useLottery from '../useLottery'
import useCountries from '../../countries/useCountries'
import useCategoriesLottery from '../../categories-award/useCategories'
import useCommerce from '../../commerce/useCommerce'
// import useParticipants from '../../participants/useParticipants'

import LotteryParticipantsTable from '../components/LotteryParticipantsTable.vue'
import LotteryAwardForm from '../components/LotteryAwardForm.vue'
import LotteryPositionForm from '../components/LotteryPositionForm.vue'

export default {
  components: {
    draggable,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    flatPickr,
    vSelect,
    BSpinner,
    BTabs,
    BTab,
    LotteryParticipantsTable,
    LotteryAwardForm,
    LotteryPositionForm,
  },
  directives: {
    Ripple,
  },
  setup() {
    const { route } = useRouter()
    const toast = useToast()

    const statuses = ref([
      { text: 'Publicado', value: 'PUBLISHED' },
      { text: 'No Publicado', value: 'NOT_PUBLISHED' },
    ])

    const dataDefault = {
      id: null,
      title: '',
      description: '',
      commerces: [],
      schedule_begin: moment().tz('America/Santiago').format(),
      schedule_end: '',
      status: statuses.value[1],
      blacklist: [],
      required_substitutes: false,
      visibility: true,
    }

    const { store, update } = useLottery()
    const { fetchCountriesSelector } = useCountries()
    const { fetchSelector: fetchCategoriesSelector } = useCategoriesLottery()
    const { fetchSelector: fetchCommerceSelector } = useCommerce()
    // const { fetchBanned: fetchParticipantBanned } = useParticipants()

    const participants = ref([])
    const awards = ref([])
    const positions = ref([])

    const countries = ref([])
    const categories = ref([])
    const commerces = ref([])
    const loading = ref(false)
    const loadingEdit = ref(false)
    const isEdit = ref(false)

    const refInputGlobalEl = ref(null)
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)

    const data = ref({ ...dataDefault })

    const awardQuantityUse = computed(() => {
      const quantityUse = {}

      let k = -1
      while (++k < positions.value.length) {
        const position = positions.value[k]
        const { from, to } = position
        const multi = to ? (to - from) + 1 : 1

        let j = -1
        while (++j < position.awards.length) {
          const award = position.awards[j]
          quantityUse[award._uid] = (quantityUse[award._uid] ?? 0) + (parseInt(award.quantity, 10) * multi)
        }
      }

      return quantityUse
    })

    const awardComputed = computed(() => {
      const list = {}

      let k = -1
      while (++k < awards.value.length) {
        const award = awards.value[k]

        if (award.image && award.name && award.quantity) {
          list[award._uid] = award
        }
      }

      return list
    })

    const getPosition = () => {
      let currentFrom = 1

      let k = -1
      while (++k < positions.value.length) {
        const { to } = positions.value[k]

        if (to) {
          currentFrom = to
        }

        ++currentFrom
      }

      return currentFrom
    }

    const awardDefault = () => ({
      _uid: uuidv4(),
      id: null,
      image: '',
      name: '',
      category_id: null,
      quantity: '',
      description: '',
    })

    const positionDefault = () => ({
      _uid: uuidv4(),
      id: null,
      from: getPosition(),
      to: null,
      awards: [],
      // awards: [{ id: null, _uid: '', quantity: '' }],
    })

    async function initEdit(id) {
      loadingEdit.value = true

      try {
        data.value.id = id
        const { data: { data: resp } } = await LotteryRequest.show(id)

        const { raffle } = resp

        raffle.status = raffle.status ? statuses.value[0] : statuses.value[1]

        if (raffle.blacklist) {
          raffle.blacklist = raffle.blacklist.map(b => countries.value.find(country => country.id === b)).filter(i => !!i)
        }

        if (raffle.commerces) {
          raffle.commerces = raffle.commerces.map(c => commerces.value.find(commerce => commerce.id === c)).filter(i => !!i)
        }

        if (raffle.required_substitutes) {
          document.querySelector('#required_substitutes').click()
        }

        data.value = raffle

        participants.value = resp.participants.data.map(p => {
          const extras = p.pivot && p.pivot.extras ? JSON.parse(p.pivot.extras) : {}
          return { ...p, ...extras }
        })

        awards.value = resp.awards.map(award => ({ ...award, category_id: categories.value.find(c => c.value === award.category_id) }))
        positions.value = resp.positions
      } catch (error) {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ocurrio un error al consultar los datos',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } finally {
        setTimeout(() => {
          loadingEdit.value = false
        }, 300)
      }
    }

    onMounted(async () => {
      isEdit.value = !!route.value.params.id
      loadingEdit.value = !!route.value.params.id

      const countriesList = await fetchCountriesSelector()
      const categoriesList = await fetchCategoriesSelector()
      const commerceList = await fetchCommerceSelector()
      // const bannedList = await fetchParticipantBanned()

      countries.value = countriesList
      categories.value = categoriesList
      commerces.value = commerceList

      awards.value = [awardDefault()]
      positions.value = [positionDefault()]

      if (route.value.params.id) {
        await initEdit(route.value.params.id)
      }
    })

    function showToast(message = '') {
      toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      throw new Error(message)
    }

    function formatData() {
      const form = JSON.parse(JSON.stringify(data.value))

      if (form.status) {
        form.status = form.status.value === 'PUBLISHED'
      }

      form.participants = participants.value?.map(participant => ({
        ...participant,
        extras: {
          only_participations: participant.only_participations?.map(award => award.index),
          weight_up: participant.weight_up,
        },
      }))

      form.awards = awards.value.filter(award => award.name && award.quantity).map(award => ({
        ...award,
        category_id: award.category_id?.value,
        quantity: award.quantity ? parseInt(award.quantity, 10) : award.quantity,
      }))

      form.positions = positions.value.filter(position => position.awards.length)

      form.blacklist = form.blacklist?.map(b => b.id) ?? []
      form.commerces = form.commerces?.map(b => b.id) ?? []

      if (!form.title) {
        showToast('El título es requerido')
      } else if (!form.description) {
        showToast('La descripción es requerida')
      } else if (!form.participants.length) {
        showToast('Participantes requeridos')
      } else if (!form.awards.length) {
        showToast('Premios requeridos')
      } else if (!form.positions.length) {
        showToast('Posiciones requeridas')
      } else if (form.awards.some(award => awardQuantityUse.value[award._uid] > award.quantity)) {
        showToast('El número de premios a entregar es mayor al número de premios disponibles')
      }

      return form
    }

    const onSubmit = async () => {
      if (loading.value) return

      loading.value = true

      try {
        const form = formatData()

        if (form.id) await update(form.id, form)
        else await store(form)

        router.back()
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    const saveAndNew = async () => {
      if (loading.value) return

      loading.value = true

      try {
        const form = formatData()

        if (form.id) await update(form.id, form)
        else await store(form)

        data.value = JSON.parse(JSON.stringify({ ...dataDefault }))
        participants.value = []

        awards.value = [awardDefault()]
        positions.value = [positionDefault()]
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    const addAward = () => {
      awards.value = [...awards.value, awardDefault()]
    }

    const destroyAward = index => {
      awards.value = [...awards.value.filter((_, i) => i !== index)]
    }

    const onChangePositions = () => {
      let currentFrom = 0

      let k = -1
      while (++k < positions.value.length) {
        const position = positions.value[k]
        let { from, to } = position

        from = ++currentFrom

        if (to) {
          to = from + (to - position.from)
          currentFrom = to
        }

        positions.value[k].from = from
        positions.value[k].to = to
      }
    }

    const addPosition = () => {
      positions.value = [...positions.value, positionDefault()]
    }

    const destroyPosition = index => {
      positions.value = [...positions.value.filter((_, i) => i !== index)]
      onChangePositions()
    }

    return {
      awardQuantityUse,
      awardComputed,
      data,
      countries,
      commerces,
      statuses,
      categories,
      refInputGlobalEl,
      refInputEl,
      refPreviewEl,
      onSubmit,
      saveAndNew,

      loading,
      loadingEdit,
      isEdit,
      participants,
      awards,
      addAward,
      destroyAward,

      positions,
      addPosition,
      destroyPosition,
      onChangePositions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.spinner-container {
  background-color: rgb(248 248 248 / 89%);
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }

  .spinner-container {
    background-color: rgb(40 48 70 / 89%);
  }
}
</style>
