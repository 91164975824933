<template>
  <div>
    <div class="d-flex align-items-baseline justify-content-between mb-1">
      <h5>Premio</h5>
      <div>
        <b-button
          :id="`${(award.key || uid)}-row-delete-icon`"
          size="sm"
          type="icon"
          variant="outline-danger"
          @click="$emit('destroy')"
        >
          <feather-icon icon="XIcon" size="14" />
        </b-button>
        <b-tooltip
          title="Eliminar premio"
          :target="`${(award.key || uid)}-row-delete-icon`"
          placement="left"
        />
      </div>
    </div>
    <b-card class="award-border" no-body>
      <b-card-body class="pb-1">
        <b-row>
          <b-col cols="12" md="2">
            <label>Imagen</label>
            <div
              class="d-flex justify-content-center position-relative cursor-pointer"
              @click="refInputEl.$refs.input.click()"
            >
              <b-img
                v-show="data.image"
                ref="refImageEl"
                :src="data.image"
                class="rounded mb-1 mb-md-0 award-image"
              />
              <div
                v-show="!data.image"
                class="image_placeholder award-image"
              />
            </div>
            <b-form-file
              ref="refInputEl"
              :disabled="loading"
              accept=".jpg, .png, .webp"
              placeholder="Elija el archivo"
              class="d-none"
              @input="onChangeFile"
            />
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Título" label-for="name">
              <b-form-input
                id="name"
                v-model="data.name"
                :disabled="loading"
                trim
              />
            </b-form-group>
            <b-form-group label="Categoria" label-for="categories">
              <v-select
                v-model="data.category_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="categories"
                input-id="categories"
                :clearable="false"
                :disabled="loading"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Cantidad" label-for="count">
              <b-form-input
                id="count"
                v-model="data.quantity"
                :disabled="loading"
                type="number"
                number
                trim
              />
            </b-form-group>
            <!-- <b-form-group label="Posición" label-for="position">
              <v-select
                v-model="data.position"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="positions"
                :clearable="false"
                :disabled="loading"
              />
            </b-form-group> -->
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="Descripción" label-for="description">
              <b-form-textarea
                id="description"
                v-model="data.description"
                :disabled="loading"
                trim
                rows="4"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BTooltip,
  BButton,
  BImg,
} from 'bootstrap-vue'
import { computed, onMounted, ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { v4 as uuidv4 } from 'uuid'
import useCategories from '@/views/categories-award/useCategories'
import { useFileToBase64 } from '@/@core/comp-functions/forms/form-utils'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BTooltip,
    BButton,
    BImg,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    award: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
    },
  },
  emits: ['update:award'],
  setup({ award }, { emit }) {
    const positions = [
      { label: '1er lugar', value: 1 },
      { label: '2do lugar', value: 2 },
      { label: '3er lugar', value: 3 },
      { label: '4to lugar', value: 4 },
      { label: '5to lugar', value: 5 },
      { label: '6to lugar', value: 6 },
      { label: '7mo lugar', value: 7 },
      { label: '8vo lugar', value: 8 },
      { label: '9no lugar', value: 9 },
      { label: '10mo lugar', value: 10 },
    ]

    const uid = uuidv4()
    const { categories } = useCategories()

    const refImageEl = ref(null)
    const refInputEl = ref(null)

    const data = computed({
      get: () => award,
      set: val => {
        emit('update:award', val)
      },
    })

    const onChangeFile = async event => {
      const base64 = await useFileToBase64(event)

      data.value.image = base64
    }

    onMounted(() => {
      // eslint-disable-next-line global-require
      // refImageEl.value.src = require('@/assets/images/pages/eCommerce/4.png')
    })

    return {
      positions,
      uid,
      data,
      categories,
      refImageEl,
      refInputEl,
      onChangeFile,
    }
  },
}
</script>

<style lang="scss">
.award-border {
  --border-color: #ebe9f1;

  border: 1px solid var(--border-color);
}

.award-image {
  width: 100%;
  height: 110px;
}

.dark-layout {
  .award-border {
    --border-color: #6c7282;
  }

  .image_placeholder {
    --color: #6c7282;
  }
}
</style>
