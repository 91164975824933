import CategoriesAwardRequest from '@/@api/categoriesAward'
import { ref, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { debouncedWatch } from '@vueuse/core'

const categories = ref([])

export default function useCategories() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // const categories = ref([])

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    {
      label: 'Categoría',
      key: 'name',
      sortable: true,
      thStyle: { width: '100%' },
    },
    { label: 'Activo', key: 'status', sortable: true },
    { label: 'Acciones', key: 'actions' },
  ]
  const perPage = ref(10)
  const totalList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  debouncedWatch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  }, { debounce: 400 })

  const fetchList = (ctx, callback) => {
    CategoriesAwardRequest.list({
      params: {
        q: searchQuery.value,
        paginate: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      },
    })
      .then(response => {
        const { data, total } = response.data.data

        callback(data.map(category => ({ ...category, loading: false })))
        totalList.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching categories list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  async function fetchSelector(isMemo = false) {
    if (isMemo && categories.value.length) {
      return Promise.resolve(categories.value)
    }

    try {
      const { data } = await CategoriesAwardRequest.select()

      const list = data.data.map(category => ({
        id: category.id,
        value: category.id,
        label: category.name,
      }))

      categories.value = list

      return list
    } catch (error) {
      throw new Error(error)
    }
  }

  async function store(body) {
    try {
      const form = { ...body, uri: '/categories' }

      const { data } = await CategoriesAwardRequest.store(form)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function update(id, body) {
    try {
      const form = { ...body, uri: '/categories' }

      const { data } = await CategoriesAwardRequest.update(id, form)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function destroy(id) {
    try {
      const { data } = await CategoriesAwardRequest.destroy(id)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  fetchSelector(true).then(list => {
    categories.value = list
  })

  return {
    categories,
    fetchList,
    fetchSelector,
    tableColumns,
    perPage,
    currentPage,
    totalList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,

    refetchData,
    store,
    update,
    destroy,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
