import { utils, writeFile } from 'xlsx'

// eslint-disable-next-line import/prefer-default-export
export function downloadExcel(jsonData, filename) {
  const worksheet = utils.json_to_sheet(jsonData)

  const workbook = utils.book_new()
  utils.book_append_sheet(workbook, worksheet, 'Datos')

  writeFile(workbook, filename)
}
